import shopifyClient from '../config/shopify.js';

export default ($el) => ({
    el: $el,
    checkoutId: null,
    defaultVariantId: $el.dataset.defaultVariantId,
    productPrice: $el.dataset.productPrice,
    productAvailable: true, // Assuming it's available, adjust based on your logic

    init() {
        this.fetchOrCreateCheckout().then(r => {
            console.log('loaded')
        });
    },


    async fetchOrCreateCheckout() {
        const existingCheckoutId = localStorage.getItem('shopify_checkout_id');
        console.log(existingCheckoutId)
        if (existingCheckoutId) {
            try {
                const checkout = await shopifyClient.checkout.fetch(existingCheckoutId);
                this.checkoutId = checkout.id; // Store only the ID, not the entire checkout object
                // Logic to update the UI based on cart items can still use checkout.lineItems etc.
            } catch (error) {
                console.error('Failed to fetch existing checkout:', error);
                // this.checkoutId = await this.createNewCheckout();
            }
        } else {
            this.checkoutId = await this.createNewCheckout();
        }
    },


    async addToCart(variantId) {
        if (!this.productAvailable) {
            alert('This product is not available.');
            return;
        }
        if (!this.checkoutId) {
            this.checkoutId = await this.createNewCheckout();
        }
        // Convert the variant ID to a global ID
        const globalVariantId = `gid://shopify/ProductVariant/${variantId}`;
        const encodedVariantId = btoa(globalVariantId);

        try {
            const lineItemsToAdd = [{variantId: encodedVariantId, quantity: 1}];
            const checkout = await shopifyClient.checkout.addLineItems(this.checkoutId, lineItemsToAdd);
            // Here you can redirect to the Shopify checkout or update your UI accordingly
            console.log('Checkout link:', checkout.webUrl);
            window.location.href = checkout.webUrl; // Redirect to Shopify checkout page
        } catch (error) {
            console.error('Failed to add item to cart:', error);
            // Handle the error appropriately
        }
    },
    async createNewCheckout() {
        try {
            // Call the Shopify API to create a new cart
            const checkout = await shopifyClient.checkout.create();

            // Return the new checkout ID
            return checkout.id;
        } catch (error) {
            console.error('Failed to create new checkout:', error);
            // Handle the error appropriately
            throw error; // Rethrow the error to handle it at a higher level
        }
    }

})