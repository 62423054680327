function applyFlickerEffect() {
    document.querySelectorAll('.flicker').forEach(element => {
        const flickerAnimations = ['flicker1', 'flicker2', 'flicker3', 'flicker4'];

        const setRandomFlicker = () => {
            element.style.animationName = flickerAnimations[Math.floor(Math.random() * flickerAnimations.length)];
            element.style.animationDuration = `${Math.random() * (9 - 5) + 5}s`;
        };

        // Set initial flicker animation
        setRandomFlicker();

        // Change flicker animation for each cycle
        element.addEventListener('animationiteration', setRandomFlicker);
    });
}

export default applyFlickerEffect;